<!--
 * @Author: diaowangtao
 * @Date: 2020-08-21 10:42:43
 * @Description: 文章详情
-->
<template>
  <div>
    <div class="content w1200" v-if="!showFlag1">
      <div class="title">{{info.title|sLang}}</div>
      <div class="time">{{info.time|sLang}}<span style="margin-left:10px"></span>{{info.author|sLang}}</div>
      <div class="text" v-html="$options.filters.sLang(info.content)"></div>
    </div>
    <no-data :showFlag="showFlag1" :contentProps="contentProps" :stylep="{'margin-bottom': '180px'}" :styleTop="{'margin-top': '120px'}"></no-data>
  </div>
</template>

<script>
import { getArticle} from '@/api/investStrategy'
import noData from '@/components/noData/noData.vue'
export default {
  name: 'newsContent',
  components: {
    noData
  },
  data() {
    return {
      info: {
        title: '',
        time: '',
        author:'',
        content: '',
      },
      showFlag1:false,//暂无数据是否显示传值
      contentProps:'暂无数据'
    }
  },
  created() {
    getArticle({ id: this.$route.query.id }).then(res => {
      console.log(res.article)
      //暂无数据
      if(res.status == '1000' || res.status == '4000'){
        this.showFlag1 = true
      }else{
        this.info.title = res.article.title
        this.info.time = this.$dayjs(res.publishTime).format('YYYY-MM-DD')
        this.info.author = '作者：'+res.article.author
        this.info.content = res.article.content
      }
    })
  },
  mounted() {},
  methods: {},
}
</script>

<style lang='scss' scoped>
.content {
  overflow: hidden;
  margin: 60px auto 100px auto;
  .title {
    font-size: 22px;
    color: #303a51;
    text-align: center;
    line-height: 28px;
  }
  .time {
    color: #8d909b;
    text-align: center;
    margin: 20px auto 40px auto;
  }
  .text {
    font-size: 18px;
    color: #575c6d;
    text-align: justify;
    line-height: 27px;
    font-family: "Microsoft YaHei" !important;
    font-family: "PingFangSC-Regular" !important;
  }
}
</style>